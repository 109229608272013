<template>
  <div id="team-create">
    <v-dialog v-model="memberDocumentPrompt" persistent scrollable>
      <upload-selected-member-document
        :record="member"
        v-if="memberDocumentPrompt"
        :showDialog="memberDocumentPrompt"
        @hideDialog="hideMemberDocumentPrompt($event)"
      ></upload-selected-member-document>
    </v-dialog>
    <v-dialog v-model="memberPhotoPrompt" persistent scrollable>
      <upload-selected-member-photo
        :record="member"
        v-if="memberPhotoPrompt"
        :showDialog="memberPhotoPrompt"
        @hideDialog="hideMemberPhotoPrompt($event)"
      ></upload-selected-member-photo>
    </v-dialog>
    <v-card>
      <v-app-bar
        color="primary"
        dense
        dark
        elevate-on-scroll
        scroll-target="#scrolling-techniques-7"
      >
        <v-toolbar-title>{{ PageTitle }}</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon dark @click="closePrompt">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>

      <v-form ref="form1" v-model="valid1" lazy-validation>
        <v-container>
          <v-card-title></v-card-title>
          <v-card-text>
            <v-row wrap>
              <v-col cols="12" md="12">
                <p>
                  <span class="text-danger">*</span> indicates required field
                </p>
              </v-col>

              <!-- <v-col cols="12" sm="6" lg="4" md="4">
                <h6><span class="text-danger">*</span> Zone</h6>
                <v-autocomplete
                  @change="getTableNameOptions"
                  :reduce="(option) => option.value"
                  :items="ZoneCodeOptions"
                  :rules="ZoneCodeRules"
                  v-model="ZoneCode"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" sm="6" lg="4" md="4">
                <h6><span class="text-danger">*</span> Table Name</h6>
                <v-autocomplete
                  @change="getMemberIdOptions"
                  :reduce="(option) => option.value"
                  :items="TableNameOptions"
                  :rules="TableNameRules"
                  v-model="TableName"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" sm="6" lg="4" md="4">
                <h6><span class="text-danger">* </span>Select Member</h6>
                <v-autocomplete
                  @change="getMemberDetails"
                  :reduce="(option) => option.value"
                  :loading="MemberIdOptionsLoading"
                  :items="MemberIdOptions"
                  :rules="MemberIdRules"
                  v-model="MemberId"
                  required
                  outlined
                  dense
                  clearable
                >
                  <template v-slot:selection="{ item, index }">
                    <img width="50" height="50" :src="item.photo" />{{
                      item.text
                    }}
                  </template>
                  <template v-slot:item="{ item }">
                    <img width="50" height="50" :src="item.photo" />{{
                      item.text
                    }}
                  </template>
                </v-autocomplete>
              </v-col> -->

              <v-col cols="12" sm="6" lg="4" md="4">
                <h6><span class="text-danger">*</span> Designation</h6>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  v-model="DesignationId"
                  :rules="DesignationIdRules"
                  :items="DesignationIdOptions"
                  :loading="DesignationIdOptionsLoading"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" sm="6" lg="4" md="4">
                <h6><span class="text-danger">*</span> Membership Id</h6>
                <v-text-field
                  v-model="MembershipId"
                  :rules="MembershipIdRules"
                  placeholder="Membership Id"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col align="center" cols="12" sm="6" lg="3" md="3">
                <v-btn
                  @click.prevent="searchForm"
                  :loading="SearchFlag"
                  color="#8950FC"
                  elevation="30"
                  class="mr-4 mt-8 white--text"
                  >Search</v-btn
                >
              </v-col>
            </v-row>

            <v-row v-if="MemberId > 0">
              <v-col cols="12" sm="12" md="12" class="text-center">
                <img
                  width="100"
                  height="auto"
                  :src="MemberDetails.MemberImage"
                />
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <label>Member Name</label>
                <h5>{{ MemberDetails.Fullname }}</h5>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <label>Membership Id</label>
                <h5>{{ MemberDetails.MembershipId }}</h5>
              </v-col>

              <v-col cols="12" sm="6" md="3">
                <label>Member Zone</label>
                <h5>{{ MemberDetails.ZoneName }}</h5>
              </v-col>

              <v-col cols="12" sm="6" md="3">
                <label>Member Lom</label>
                <h5>{{ MemberDetails.LomName }}</h5>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <label>Member Designation</label>
                <h5>{{ MemberDetails.MemberDesignation }}</h5>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <label>Member Contact Number</label>
                <h5>{{ MemberDetails.MobileNo }}</h5>
              </v-col>

              <v-col cols="12" sm="6" md="3">
                <label>Member Email</label>
                <h5>{{ MemberDetails.EmailId }}</h5>
              </v-col>

              <v-col cols="12" sm="6" md="3">
                <label>Member Address</label>
                <h5>{{ MemberDetails.AddressTxt }}</h5>
              </v-col>
            </v-row>

            <v-row wrap v-if="MemberId > 0">
              <v-col cols="12" sm="6" md="3">
                <h4>Date of Birth</h4>
                <v-menu
                  v-model="menu1"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      required
                      v-model="MemberDetails.DOB"
                      label=" Date of Birth"
                      hint="Select a Date of Birth"
                      persistent-hint
                      readonly
                      v-on="on"
                      background-color="#F3F6F9"
                      class="form-control form-control-lg form-control-solid"
                      validate-on-blur
                      solo
                      dense
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="MemberDetails.DOB"
                    @input="menu1 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <h4>Profile Update</h4>
                <v-chip
                  :color="`${
                    MemberDetails.ProfileUpdate == 1 ? '#0f9d58' : '#f4b400'
                  }`"
                  draggable
                  dark
                  >{{
                    MemberDetails.ProfileUpdate == 1 ? "Completed" : "Pending"
                  }}
                </v-chip>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <h4>Photo Update</h4>
                <v-chip
                  :color="`${
                    MemberDetails.PhotoUpdate ? '#0f9d58' : '#f4b400'
                  }`"
                  draggable
                  dark
                  >{{ MemberDetails.PhotoUpdate ? "Completed" : "Pending" }}
                </v-chip>
                &nbsp;
                <v-icon
                  icon
                  size="25px"
                  color="primary"
                  @click.prevent="memberPhotoPrompt = true"
                  v-if="MemberDetails.PhotoUpdate == false"
                >
                  mdi-cloud-upload
                </v-icon>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <h4>Age Proof Document Upload</h4>
                <v-chip
                  :color="`${
                    MemberDetails.DocumentUpdate ? '#0f9d58' : '#f4b400'
                  }`"
                  draggable
                  dark
                  >{{ MemberDetails.DocumentUpdate ? "Completed" : "Pending" }}
                </v-chip>
                &nbsp;
                <v-icon
                  icon
                  size="25px"
                  color="primary"
                  @click.prevent="memberDocumentPrompt = true"
                  v-if="MemberDetails.DocumentUpdate == false"
                >
                  mdi-cloud-upload
                </v-icon>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <p></p>
            <p></p>
            <p></p>
            <v-layout row>
              <v-flex justify-center>
                <v-btn
                  :disabled="!valid2"
                  @click.prevent="confirmAlert"
                  :loading="SubmitFlag"
                  elevation="30"
                  shaped
                  tile
                  medium
                  color="#1db954"
                  class="font-size-h6 px-5 mr-2 white--text"
                >
                  Save
                </v-btn>
                <v-btn
                  @click="closePrompt"
                  elevation="30"
                  shaped
                  tile
                  medium
                  color="#a52a2a"
                  class="font-size-h6 px-5 mr-2 white--text"
                >
                  Close
                </v-btn>
              </v-flex>
            </v-layout>
          </v-card-actions>
        </v-container>
      </v-form>
    </v-card>
  </div>
</template>
  
  <script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";
import UploadSelectedMemberDocument from "@/view/pages/members/UploadSelectedMemberDocument.vue";
import UploadSelectedMemberPhoto from "@/view/pages/members/UploadSelectedMemberPhoto.vue";

export default {
  mixins: [common],
  components: {
    UploadSelectedMemberDocument,
    UploadSelectedMemberPhoto,
  },
  props: {
    AddRecordPrompt: {
      type: Boolean,
      required: true,
    },
    ZoneCode: {
      type: Number,
    },
    TableName: {
      type: Number,
    },
  },
  data() {
    return {
      valid1: true,
      valid2: false,
      LoadingFlag: false,
      SearchFlag: false,
      SubmitFlag: false,

      ResultFlag: false,

      refreshButtonFlag: false,
      menu1: false,

      memberDocumentPrompt: false,
      memberPhotoPrompt: false,

      member: {},
      Member: {},
      MemberDetails: {},

      rows: [],
      item: {},

      ZoneCodeRules: [(v) => !!v || "Zone is required"],
      // ZoneCode: "",
      ZoneCodeOptions: [],

      TableNameRules: [(v) => !!v || "Team Name is required"],
      // TableName: "",
      TableNameOptions: [],

      DesignationIdRules: [(v) => !!v || "Designation is required"],
      DesignationId: "",
      DesignationIdOptions: [],
      DesignationIdOptionsLoading: false,

      JciYearCodeRules: [(v) => !!v || "Year is required"],
      JciYearCodeOptions: [],
      JciYearCodeOptionsLoading: false,

      MembershipId: "",
      MembershipIdRules: [(v) => !!v || "MembershipId is required"],

      MemberIdRules: [(v) => !!v || "Member is required"],
      MemberId: "",
      MemberIdOptions: [],
      MemberIdOptionsLoading: false,

      AllMembers: [],

      CurrentYearId: "",

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      JciYearCode: "",
      YearName: "",
      NextYearCode: "",
      NextYearName: "",
    };
  },
  computed: {},
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        var flag = tr.PagePermissionFlag;
        console.log("PagePermissionFlag=" + flag);

        if (!flag) {
          this.$router.push("/access-denied");
        }
        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });
        this.JciYearCode = CurrentJciYearId;

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;
        this.CurrentYearId = CurrentJciYearId;

        this.pageData();
      }
    },
    JciYearCodeOptions: function () {
      console.log("watch JciYearCodeOptions");
      this.JciYearCodeOptionsLoading = false;
    },
    DesignationIdOptions: function () {
      console.log("watch DesignationIdOptions");
      this.DesignationIdOptionsLoading = false;
    },
    MemberIdOptions: function () {
      console.log("watch MemberIdOptions");
      this.MemberIdOptionsLoading = false;
      this.getMemberDetails();
    },
    LomCodeOptions: function () {
      this.LomCodeOptionsLoading = false;
    },
    ZoneCodeOptions: function () {
      this.ZoneCodeOptionsLoading = false;
    },
    MembershipId: function () {
      this.MemberDetails = {};
      this.MemberId = "";
    },
    MemberDetails: function () {
      console.log("watch MemberDetails");
      console.log("valid1" + this.valid1);
      var n1 = Object.keys(this.MemberDetails).length;
      console.log("MemberDetails" + n1);
      this.valid2 = n1 > 0 && this.valid1 ? true : false;
      console.log("valid2" + this.valid2);
    },
  },
  mounted() {},
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "jcom_table_board",
        Action: "create",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
      this.pageData();
    },
    hideMemberDocumentPrompt(flag) {
      console.log("hideMemberDocumentPrompt called");
      console.log({ flag });
      this.memberDocumentPrompt = false;
      if (flag) {
        this.MemberDetails.DocumentUpdate = true;
      }
    },
    hideMemberPhotoPrompt(flag) {
      console.log("hideMemberPhotoPrompt called");
      console.log({ flag });
      this.memberPhotoPrompt = false;
      if (flag) {
        this.MemberDetails.PhotoUpdate = true;
      }
    },
    pageData() {
      console.log("pageData called");
      var ZoneCode = this.ZoneCode;
      console.log({ ZoneCode });
      var TableName = this.TableName;
      console.log({ TableName });
      this.getYearCodeOptions();
      // this.getZoneCodeOptions();
      this.getMemberIdOptions();
      this.getDesignationIdOptions();
    },
    resetForm() {
      console.log("resetForm called");
      this.$refs.form1.reset();
      this.JciYearCode = "";
      this.MemberDetails = {};
      this.MemberId = "";
      this.DesignationId = "";
      console.log(
        "JciYearCode = " +
          this.JciYearCode +
          ", MemberId = " +
          this.MemberId +
          ", DesignationId = " +
          this.DesignationId
      );
    },
    closePrompt() {
      console.log("closePrompt called");
      this.$emit("hideAddRecordPrompt", this.ResultFlag);
    },
    getYearCodeOptions() {
      console.log("getYearCodeOptions called");
      this.JciYearCodeOptionsLoading = true;
      var selectbox1_source = "JciYearCode";
      var selectbox1_destination = "JciYearCodeOptions";
      var selectbox1_url = "api/jci-year/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getZoneCodeOptions() {
      console.log("getZoneCodeOptions called");
      this.TableName = "";
      var yearCode = this.CurrentYearId;
      console.log("yearCode=" + yearCode);
      if (yearCode != "") {
        var selectbox1_source = "ZoneCode";
        var selectbox1_destination = "ZoneCodeOptions";
        var selectbox1_url = "api/year-wise-lom/zone-options";
        var selectbox1_conditions_array = {
          UserInterface: 2,
          YearCode: yearCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "";
        if (yearCode == "") {
          message += "Year should not be empty. ";
        }
        this.alert = {
          flag: true,
          color: "error",
          message: message,
        };
      }
    },
    getTableNameOptions() {
      console.log("getTableNameOptions called");
      // this.MemberId = "";
      var selectbox1_source = "TableName";
      var selectbox1_destination = "TableNameOptions";
      var selectbox1_url = "api/jcom/table/options";

      var Zone = this.ZoneCode;
      if (Zone !== "") {
        var selectbox1_conditions_array = {
          UserInterface: 2,
          Zone: Zone,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
    getMemberIdOptions() {
      console.log("getMemberIdOptions called");
      // this.MemberIdOptions = [];
      this.MemberIdOptionsLoading = true;
      var selectbox1_source = "MemberId";
      var selectbox1_destination = "MemberIdOptions";
      var selectbox1_url = "api/members/options";
      var ZoneCode = this.ZoneCode;
      var TableName = this.TableName;
      console.log("ZoneCode = " + ZoneCode + ", TableName = " + TableName);
      if (ZoneCode != "" && TableName != "") {
        var selectbox1_conditions_array = {
          UserInterface: 6,
          ZoneCode: ZoneCode,
          TableName: TableName,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
    getMemberDetails() {
      console.log("getMemberDetails called");
      this.AllMembers = [];
      var temp_code = this.MemberId;
      console.log("temp_code=" + temp_code);
      var idx = this.MemberIdOptions.map((e) => e.value).indexOf(temp_code);
      console.log("idx=" + idx);
      if (idx >= 0) {
        var member = this.MemberIdOptions[idx];
        console.log("member=" + JSON.stringify(member));
        // this.MemberDetails = member;
        this.member = member;
        console.log("Membership Id = " + member.membership_id);
        this.MembershipId = member.membership_id;
        // this.searchForm();
      }
    },
    getDesignationIdOptions() {
      console.log("getDesignationIdOptions called");
      this.DesignationIdOptionsLoading = true;
      var selectbox1_source = "DesignationId";
      var selectbox1_destination = "DesignationIdOptions";
      var selectbox1_url = "api/designation/options";
      var selectbox1_conditions_array = {
        UserInterface: 4,
        CategorySubName: "designation_jcom_lo",
        OrganizationTypeId: 8,
        ExceptDesignationId: [1, 5, 7, 161],
      };

      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    confirmAlert() {
      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);
      if (validate1) {
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: `Save`,
          confirmButtonColor: "#3085d6",
          cancelButtonText: `Cancel`,
          cancelButtonColor: "#d33",
        }).then((result) => {
          if (result.isConfirmed) {
            this.submitForm();
          } else {
            this.sweetAlert("error", "You cancelled the process", true);
          }
        });
      } else {
        this.toast("error", "Kindly fill the required fields", true);
      }
    },
    searchForm() {
      console.log("searchForm is called");
      var MembershipId = this.MembershipId;
      console.log("MembershipId=" + MembershipId);
      if (MembershipId != "") {
        this.SearchFlag = true;
        this.Member = {};
        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/members/search";
        var upload = {
          UserInterface: 2,
          MembershipId: this.MembershipId,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );
        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;
        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));
            // thisIns.pageLoadingOff()
            thisIns.SearchFlag = false;
            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            if (flag == 1) {
              thisIns.toast("success", output);
              thisIns.MemberDetails = records;
              thisIns.MemberId = records.MemberId;
              thisIns.getMemberIdOptions();
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SearchFlag = false;
          });
      } else {
        var message = "Membership Id is required";
        this.sweetAlert("error", message, false);
      }
    },
    submitForm() {
      console.log("submitForm called");
      var validate1 = this.$refs.form1.validate();

      var Member = this.MemberDetails;
      console.log("Member=" + JSON.stringify(Member));

      var YearCode = this.JciYearCode;
      var ZoneCode = this.ZoneCode;
      var LomCode = Member.LomId;
      var TableName = this.TableName;
      var MemberId = this.MemberId;
      var DesignationId = this.DesignationId;

      if (
        validate1 &&
        LomCode != "" &&
        TableName != "" &&
        MemberId > 0 &&
        DesignationId != ""
      ) {
        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/year-wise-designation/bulk-update";

        var upload = {
          UserInterface: 9,
          OrganizationTypeId: 8,
          YearCode: YearCode,
          ZoneCode: ZoneCode,
          LomCode: LomCode,
          TableName: TableName,
          MemberId: MemberId,
          DesignationId: DesignationId,
          DateOfBirth: Member.DOB,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );
        this.LoadingFlag = true;

        const thisIns = this;
        var output = "";
        var records = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.LoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            console.log("output=" + output + ", flag=" + flag);

            records = response.data.records;
            console.log({ records });

            if (flag == 1) {
              thisIns.ResultFlag = true;
              thisIns.resetForm();
              thisIns.closePrompt();
              thisIns.sweetAlert("success", output, true);
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SubmitFlag = false;
            thisIns.sweetAlert("error", error, false);
          });
      } else {
        var message = "";
        if (!validate1) {
          message += "Kindly fill the required fields";
        }
        this.sweetAlert("error", message, false);
      }
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>
  <style lang="scss">
#team-create {
  .notes {
    color: red;
    font-size: 18px;
    font-weight: 500;
    line-height: 2;
  }
  .notes-title {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
  .notes-content {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
}
</style>